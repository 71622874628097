import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DiscordIcon from './DiscordIcon';

import InfoIcon from '@mui/icons-material/Info';



const socialMediaLinks = [
  {
    icon: DiscordIcon,
    href: 'https://discord.com/',
    title: 'Discord',
  },
  {
    icon: TwitterIcon,
    href: 'https://x.com/zombietraingame',
    title: 'Twitter',
  },
  {
    icon: InstagramIcon,
    href: 'https://www.instagram.com/zombie.train/',
    title: 'Instagram',
  },
  {
    icon: YouTubeIcon,
    href: 'https://www.youtube.com/@ZOMBIETRAIN-vg5mo',
    title: 'YouTube',
  },
  {
    icon: FacebookIcon,
    href: 'https://www.facebook.com/profile.php?id=61558972854377',
    title: 'Facebook',
  },
]

const companyLinks = [
  {
    href: '#',
    title: 'About us',
  },
  {
    href: '#',
    title: 'Privacy',
  },
  {
    href: '#',
    title: 'Terms',
  },
]

function Copyright() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {xs: 'column', sm: 'row'},
        gap: '1px',
      }}
    >
      <Typography variant="body2" color="text.secondary" mt={1}>
        {'Copyright © '}<Link href="https://zombietra.in/">ZombieTrain&nbsp;</Link>
      </Typography>
      <Typography variant="body2" color="text.secondary" mt={1}>
        {new Date().getFullYear() + ' - All rights reserved.'}
      </Typography>
    </Box>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'row' },
          width: '40%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            Company
          </Typography>
          {
            companyLinks.map((link) => (
              <Link key={link.title} color="text.secondary" href={link.href}>
                <InfoIcon sx={{ marginRight: '5px', marginBottom: '2px' }} /> {link.title}
              </Link>
            ))
          }
        </Box>
        <Box
          sx={{
            display: { xs: 'flex', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight={600} id='social-media-links'>
            Social Media
          </Typography>
          {
            socialMediaLinks.map((link) => (
              <Link key={link.title} color="text.secondary" href={link.href}>
                <link.icon sx={{ marginRight: '5px', marginBottom: '2px' }} /> {link.title}
              </Link>
            ))
          }
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Copyright />
        </div>
      </Box>
    </Container>
  );
}