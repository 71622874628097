import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import yesterdaysHeroCrown from "../images/yesterdays-hero-crown.png";

interface YesterdaysHeroData {
  user_name: string;
  total_score: number;
}

export default function YesterdaysHero() {
  const [yesterdaysHeroData, setYesterdaysHeroData] =
    useState<YesterdaysHeroData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  let yesterdays = new Date(Date.now() - 86400000); // that is: 24 * 60 * 60 * 1000
  const offset = yesterdays.getTimezoneOffset();
  yesterdays = new Date(yesterdays.getTime() - offset * 60 * 1000);
  const url =
    process.env.NODE_ENV === "development" ? "http://localhost:8000" : "";

  useEffect(() => {
    fetch(
      url +
        `/api/leaderboard/?limit=1&date=` +
        yesterdays.toISOString().split("T")[0]
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          throw new Error("No data available for the previous day");
        }
        setYesterdaysHeroData(data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []); // Empty dependency array means this effect runs once on mount
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  return (
    <Container
      sx={{
        maxWidth: "1024px",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <h2>Yesterday's Hero</h2>
      <img src={yesterdaysHeroCrown} alt="Yesterday's Hero Crown" />
      <p>
        <strong>{yesterdaysHeroData?.user_name}</strong> with a score of{" "}
        <strong>{yesterdaysHeroData?.total_score}</strong>.
      </p>
    </Container>
  );
}
