import * as React from "react";
import { AppProps } from "../App";
import Box from "@mui/material/Box";
import AppAppBar from "./AppAppBar";
import { alpha } from "@mui/material";
import Footer from "./Footer";
import Container from "@mui/material/Container";
import Leaderboard from "./Leaderboard";
import { brand } from "../getLPTheme";
import PreviousDayHero from "./YesterdaysHero";

export default function LeaderboardPage({ mode, toggleColorMode }: AppProps) {
  return (
    <Box>
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box
        sx={(theme) => ({
          width: "100%",
          backgroundImage:
            theme.palette.mode === "light"
              ? `linear-gradient(180deg, ${brand[100]}, #FFF)`
              : `linear-gradient(${brand[800]}, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <PreviousDayHero />
          <Leaderboard interactive={true} />
        </Container>

        <Footer />
      </Box>
    </Box>
  );
}
