import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import IntroVideo from "./IntroVideo";
import appStoreImage from "../images/app-store.png";
import googlePlayImage from "../images/google-play.png";
import { brand } from "../getLPTheme";
import WorldMap from "./WorldMap";

const images = [
  {
    url: appStoreImage,
    href: "https://apps.apple.com/pl/app/zombietrain-choochoo/id6479581262",
    title: "App Store",
  },
  {
    url: googlePlayImage,
    href: "https://play.google.com/store/apps/details?id=com.KorubovGames.ZombieTrain",
    title: "Google Play",
  },
];

const logoStyle = {
  maxWidth: "200px",
  width: "100%",
  outline: {xs: "0.5px solid white", sm: "1px solid white"},
  borderRadius: "5pt",
};

const gridStyle = {
  marginTop: "1%",
  marginRight: "7%",
  marginLeft: "7%",
};

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? `linear-gradient(180deg, ${brand[100]}, #FFF)`
            : `linear-gradient(${brand[800]}, ${alpha("#090E10", 0.0)})`,
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <IntroVideo />
        <Container
          id="marketplaces"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            mt: { xs: 2, sm: 3 },
          }}
        >
          {images.map((image) => (
            <Grid item key={image.title} style={gridStyle}>
              <a href={image.href} target="_blank" rel="noreferrer">
                <Box
                  component="img"
                  src={image.url}
                  sx={logoStyle}
                  alt="ZombieTrain"
                />
              </a>
            </Grid>
          ))}
        </Container>
        <Container
          id="world-map"
          sx={(theme) => ({
            mt: { xs: 2, sm: 5 },
            alignSelf: "center",
            height: { xs: 200, sm: 700 },
            width: "100%",
            backgroundSize: "cover",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          })}
        >
          <a href="/world-map">
            <WorldMap interactive={true} />
          </a>
        </Container>
      </Container>
    </Box>
  );
}
