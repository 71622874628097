import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AppAppBar from "./AppAppBar";
import { AppProps } from "../App";
import Hero from "./Hero";
import Footer from "./Footer";
import Leaderboard from "./Leaderboard";

export default function LandingPage({ mode, toggleColorMode }: AppProps) {
  return (
    <Box>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Divider />
      <a href="/leaderboard">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Leaderboard interactive={false} />
        </Box>
      </a>

      <Box sx={{ bgcolor: "background.default" }}>
        <Divider />
        <Footer />
      </Box>
    </Box>
  );
}
