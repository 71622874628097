import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function Leaderboard({ interactive = false }: { interactive: boolean }) {
  interface Row {
    position: number;
    user_id: number;
    user_name: string;
    total_score: number;
  }

  const [rows, setRows] = useState<Row[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const url =
    process.env.NODE_ENV === "development" ? "http://localhost:8000" : "";

  const fetchData = () => {
    fetch(url + "/api/leaderboard/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setRows(data.slice(0, 100));
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000); // Fetch data every minute
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []); // Empty dependency array means this effect runs once on mount

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <TableContainer
      id="leaderboard"
      component={Paper}
      className="leaderboard-table"
      sx={{
        width: "70%",
        maxWidth: "1000px",
        minWidth: "300px",
      }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>Position</TableCell>
            <TableCell align="left">Player Name</TableCell>
            <TableCell align="right">Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.user_id}>
              <TableCell component="th" scope="row">
                {row.position}
              </TableCell>
              <TableCell align="left">{row.user_name}</TableCell>
              <TableCell align="right">{row.total_score}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Leaderboard;
