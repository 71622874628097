import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import introVideo from '../videos/intro-video.mp4';

export default function IntroVideo() {

    return (
      <Card sx={{ 
            maxWidth: '1024px',
        }}>
        <CardMedia
            component='video'
            src={ introVideo }
            autoPlay
            muted
            sx={{ 
                width: '100%',
                height:'56.25%' //16:9 aspect ratio
            }}
        />
      </Card>
    );
  }